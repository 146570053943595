/* eslint-disable @typescript-eslint/naming-convention */
import { User } from 'src/app/models/user';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AuthService } from '../_core/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  env = environment;
  user: BehaviorSubject<User> = new BehaviorSubject({});
  authUser: User;
  constructor(
    private http: HttpClient,
    public authService: AuthService
  ) { }

  getUser(): User {
    return this.user.getValue();
  }
  setUser(user: User) {
    this.user.next(user);
  }
  //
  getById(recordId): Observable<User> {
    const url = `${this.env.apiUrl}/getUserById`;
    const body = {
      id: recordId
    };
    return this.http.post(url, body).pipe(
      map((data: any) => data)
    );
  }

  getAll(activeOnly): Observable<any> {
    this.authUser = this.authService.getAuthUser();
    const url = `${this.env.apiUrl}/getUsers?activeOnly=${activeOnly}`;
    const body = {
      lhu_seq: this.authUser.lhu_seq
    };
    return this.http.post(url, body).pipe(
      map((data: any) => data.users)
    );
  }

  saveUser(pUser: User): Observable<any> {
    this.authUser = this.authService.getAuthUser();
    pUser.loginId = this.authUser.userId;
    pUser.lhu_seq = this.authUser.lhu_seq;
    const body = pUser;
    const url = `${this.env.apiUrl}/saveUser`;
    return this.http.post(url, body).pipe(
      map((data: any) => data)
    );
  }

  deleteUser(pUser: User): Observable<any> {
    this.authUser = this.authService.getAuthUser();
    pUser.loginId = this.authUser.userId;
    pUser.lhu_seq = this.authUser.lhu_seq;
    const body = pUser;
    const url = `${this.env.apiUrl}/deleteUser`;
    return this.http.post(url, body).pipe(
      map((data: any) => data)
    );
  }

}
