import { ThemeOption } from './../../../models/_core/theme-option';
import { NavigationService } from 'src/app/services/navigation/navigation.service';
import { UserState } from './../../../models/_core/user-state';
import { HelperUtilitiesService } from 'src/app/services/_core/helper-utilities/helper-utilities.service';
import { User } from 'src/app/models/user';
import { NavController, AlertController, ModalController } from '@ionic/angular';
import { environment } from '../../../../environments/environment';
import { Injectable, HostListener } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, observable, interval, BehaviorSubject, Subscription } from 'rxjs';
import { map, catchError, mapTo, first } from 'rxjs/operators';
import { NotificationsService } from '../notifications/notifications.service';
import { StorageService } from '../storage/storage.service';
import { ConstantsService } from '../constants/constants.service';
import * as moment from 'moment';
import { AuthState } from 'src/app/models/_core/auth-state';
import { promise } from 'protractor';
import { ConnectedOverlayPositionChange } from '@angular/cdk/overlay';

/**
 * ID: bh-auth-service
 * Name: BH Auth Service
 * Description: Service used for managing authentication and user state
 * Version: 5
 *
 * ==============================
 * Change Log
 * ==============================
 * 2021-07-02 - MW - v1: Initial dev
 * 2021-07-13 - MW - v2: Implemented userState
 * 2021-07-27 - MW - v3: Improved open modal + alert handling; improved UX
 * 2022-05-23 - MW - v4: Updated depreciated value/error handling
 * 2022-05-27 - MW - v5: Implemented user state and theme subjects
 */
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  env = environment;
  authUser: BehaviorSubject<User> = new BehaviorSubject({});
  loginUser: User = {};
  userState: UserState = {};
  userStateSubject: BehaviorSubject<UserState> = new BehaviorSubject({});
  themeSubject: BehaviorSubject<ThemeOption> = new BehaviorSubject('M');
  apiUrl: any;
  timeoutWarningMs = 60000;
  timeoutLogoutMs = 120000;
  inactivitySubject = new BehaviorSubject<number>(0);
  inactivityTimer = null;
  targetUrl = '';

  constructor(
    private http: HttpClient,
    private notifications: NotificationsService,
    private storageService: StorageService,
    private nav: NavController,
    private alertCtrl: AlertController,
    private constants: ConstantsService,
    private modalCtrl: ModalController,
    private helpers: HelperUtilitiesService,
    private navService: NavigationService
  ) {
    this.getUserStateFromStorage();
  }

  /**
   * Gets Auth User object
   * Recommend subscribing to authUser directly
   */
  getAuthUser(): User {
    return this.authUser.getValue();
  }

  /***
   * Updates Auth User object with provided object
   * @param authUser User object to replace existing value
   */
  setAuthUser(authUser: User) {
    this.authUser.next(authUser);
  }

  /**
   * Gets User State object
   * Recommend subscribing to userStateSubject directly
   */
   getUserState(): UserState {
    return this.userStateSubject.getValue();
  }

  /***
   * Updates User State subject object
   * @param userState User State to update with
   */
  setUserState(userState: UserState) {
    this.userStateSubject.next(userState);
  }

  /**
   * Gets active theme
   * Recommend subscribing to themeSubject directly
   */
   getTheme(): ThemeOption {
    return this.themeSubject.getValue();
  }

  /***
   * Updates theme subject object
   * @param theme ThemeOption to update with
   */
  setTheme(theme: ThemeOption) {
    this.themeSubject.next(theme);
  }


  /***
   * Gets the user's state from storage
   */
  async getUserStateFromStorage() {
    this.userState = await this.storageService.getData('userState');
  }

  /***
   * Save the user's state to local storage
   */
  async saveUserStateToStorage() {
    // if (!this.env.storeToken && this.userState.authUser.token) {
    //   this.userState.authUser.token = null;
    // }
    this.userStateSubject.next(this.userState);
    this.storageService.saveData('userState', this.userState);
  }

  /**
   * Starts inactivity timer.
   * Should be called after successfully logging in
   */
  public startInactivityTimer() {
    if (this.env.requireTimeout) {
      this.timeoutLogoutMs = this.env.timeoutThreshold;
      this.timeoutWarningMs = this.timeoutLogoutMs - 30000;
      this.inactivityTimer = setInterval(() => {
        let time = this.inactivitySubject.getValue();
        time += 1000;
        // console.log('Inactivity: ', time)
        this.inactivitySubject.next(time);
        this.checkForTimeout();
      }, 1000);
    }
  }

  /**
   * Check for session timeout, display appropriate alert if timing out.
   */
  public async checkForTimeout() {
    const time = this.inactivitySubject.getValue();
    if (time === this.timeoutWarningMs) {
      const alert = await this.alertCtrl.create({
        header: 'Still there?',
        message: 'You will be signed out soon due to inactivity.',
        cssClass: 'wide-alert warning',
        backdropDismiss: false,
        buttons: [
          {
            text: 'Stay signed in',
            cssClass: 'primary',
            handler: (val) => {
              this.bumpInactivityTimer();
            }
          },
          {
            text: 'Sign out',
            handler: async (val) => {
              await this.dismissAllModalsAndAlerts();
              this.logout(false, true);
            }
          }
        ]
      });
      await alert.present();
    } else if (time === this.timeoutLogoutMs) {
      await this.dismissAllModalsAndAlerts();
      this.logout(true, true);
    }
  }

  /**
   * Dismisses all open alerts and modals
   */
  async dismissAllModalsAndAlerts(): Promise<boolean> {
    // Dismiss alerts
    for (let i = 0; i < 25; i++) {
      const alert = await this.alertCtrl.getTop();
      if (alert) {
        await alert.dismiss();
      } else {
        break;
      }
    }

    // Dismiss modals
    for (let i = 0; i < 25; i++) {
      const modal = await this.modalCtrl.getTop();
      if (modal) {
        await modal.dismiss();
      } else {
        break;
      }
    }

    return Promise.resolve(true);

  }

  /**
   * Bumps activity timer, preventing auto-timeout
   */
  public bumpInactivityTimer() {
    this.inactivitySubject.next(0);
  }

  /***
   * Validate User is still active in AD
   */
  validateUser(): Observable<any> {
    // Prepare request
    const url = environment.apiUrl + `/validateAD`;
    // Send request
    return this.http.get(url).pipe(
      map((data: any) => data),
      catchError(err => of(err))
    );
  }

  /***
   * Logs user into application
   * @param userId User ID
   * @param password  Password
   * @returns User Login Payload
   */
  login(userId, password): Observable<any> {
    const url = `${this.env.apiUrl}/login`;
    const body = {
      username: userId,
      password
    };
    //console.log(url);
    return this.http.post(url, body).pipe(
      map((data: any) => {
        data.userid = userId;
        data.userId = userId;
        this.handleLoginResponse(data, password);
        return data;
      }),
      catchError(error => of(error))
    );
  }

  /***
   * Process user response data, determining login status
   * @param data Login Response Data
   */
  async handleLoginResponse(data: any, password) {
    if (data.status && data.status === 'S') {
      this.startInactivityTimer();
      //console.log('***data', data);
      const ctoken = data.token;
      //console.log('***data token', ctoken);
      const loginUser =  Object.assign({}, data.userLogin[0]);
      loginUser.firstSubmitted = true;
      loginUser.token = data.token;
      loginUser.loginId = data.userId;
      loginUser.loginPassword = password;
      loginUser.userId = data.userId;
      loginUser.token = ctoken;
      loginUser.testData = 'abcdef';
      loginUser.fullname = loginUser.firstname + ' ' + loginUser.lastname;
      //console.log('***login loginUser', loginUser);
      this.setAuthUser(loginUser);

      //this.setAuthUser(this.loginUser);
      this.userState.sessionAppVersion = this.env.appVersion + '-' + this.env.env;
      this.userState.userId = loginUser.userId;
      this.userState.environment = this.env;
      this.userState.lastLoggedIn = moment().format('M/D/YYYY HH:mm');
      this.userState.authState = AuthState.LOGGED_IN;
      this.userState.authUser = loginUser;
      this.userState.token = ctoken;
      //console.log('***login userState', this.userState);
      this.saveUserStateToStorage();
    }
    return;
  }

  /***
   * Logs user out
   * @param isExpired Determines if session expired
   * @param redirectToLogin Designates redirection to login page
   */
  logout(isExpired = false, redirectToLogin = true) {
    this.authUser.next(null);
    this.inactivitySubject.next(0);
    clearInterval(this.inactivityTimer);
    this.inactivityTimer = null;
    this.alertCtrl.getTop().then(alert => {
      if (alert) {
        alert.dismiss();
      }
    });

    if (isExpired) {
      this.userState.authState = AuthState.EXPIRED;
      this.notifications.showAlert('Session expired', 'You were signed out due to inactivity.', 'danger');
    } else {
      this.userState.authState = AuthState.LOGGED_OUT;
    }

    this.storageService.removeData('userState');

    if (redirectToLogin) {
      this.navService.navigateBack('login');
    }
  }
}
