import { Network } from '@awesome-cordova-plugins/network/ngx';
import { AES256 } from '@awesome-cordova-plugins/aes-256/ngx';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';
// import { OneSignal } from '@awesome-cordova-plugins/onesignal/ngx';
import { TouchID } from '@awesome-cordova-plugins/touch-id/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { IonicStorageModule } from '@ionic/storage-angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginRouteGuardService } from './services/_core/login-route-guard/login-route-guard.service';
import { BhAnalyticsRouteGuardService } from './services/_core/bh-analytics-route-guard/bh-analytics-route-guard.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { VerlockerPageModule } from './pages/_core/verlocker/verlocker.module';
import { InterceptorService } from './services/_core/interceptor/interceptor.service';
import { UserMenuPopoverPageModule } from './pages/_core/user-menu-popover/user-menu-popover.module';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      mode: 'md',
    }),
    FormsModule,
    HttpClientModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatDialogModule,
    MatMomentDateModule,
    AppRoutingModule,
    IonicStorageModule.forRoot(),
    BrowserAnimationsModule,
    VerlockerPageModule,
    UserMenuPopoverPageModule,
  ],
  providers: [
    AES256,
    StatusBar,
    SplashScreen,
    Device,
    InAppBrowser,
    Keyboard,
    Network,
    // OneSignal,
    // PinCheck, -- Not Supported in Ionic 6
    TouchID,
    LoginRouteGuardService,
    BhAnalyticsRouteGuardService,
    BarcodeScanner,
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
