import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { AboutPage } from './../about/about.page';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/_core/auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { PopoverController, NavController, ModalController } from '@ionic/angular';
import { NavigationService } from 'src/app/services/navigation/navigation.service';
import { LegendPage } from '../../legend/legend.page';
import { User } from 'src/app/models/user';
import { DischargesPage } from '../../discharges/discharges.page';
import { SafetyguidelinesPage } from '../../safetyguidelines/safetyguidelines.page';
import { Patient, PatientControl } from 'src/app/models/patient';
import { StatusPage } from 'src/app/pages/status/status.page';
import { DdoptionsService } from 'src/app/services/ddoptions/ddoptions.service';
@Component({
  selector: 'app-user-menu-popover',
  templateUrl: './user-menu-popover.page.html',
  styleUrls: ['./user-menu-popover.page.scss'],
})
export class UserMenuPopoverPage implements OnInit {
  patients: Patient[] = [];
  patient: Patient = {};
  //env = environment;
  versionDisplay = '';
  authUser: User;
  unitType: string;
  //Test
  patientControl: PatientControl = {};
  selectedSleepNote = '';
  selectedCheckinLocation = '';
  selectedLrSeq = '';
  //
  constructor(
    private authService: AuthService,
    private popoverCtrl: PopoverController,
    private navService: NavigationService,
    private modalCtrl: ModalController,
    private analytics: BhAnalyticsService,
    private ddoptionsService: DdoptionsService
  ) { }

  ngOnInit() {
  }

  ionViewWillEnter() {
    this.authUser = this.authService.getAuthUser();
    this.versionDisplay = environment.env + ' - ' + environment.appVersion;
    this.loadData();
  }

  async loadData() {
    this.unitType = this.ddoptionsService.getUnitType();
    console.log('LOAD DATA - Unit type', this.unitType);
  }

  openPage(page) {
    this.analytics.clickEvent('user-menu-popover: open-' + page, '');
    switch (page) {
      case 'logout':
        this.authService.logout();
        break;
      case 'about':
        this.openAbout();
        break;
      case 'opendischarges':
        this.openDischarges();
        break;
      case 'openlegend':
        this.openLegend();
        break;
      case 'opensafetyguidelines':
        this.openSafetyguidelines();
        break;
      case 'opencheckinlocation':
        this.openCheckinLocation();
        break;
      default:
        this.navService.navigateRoot(page);
        break;
    }
    this.popoverCtrl.dismiss();
  }

  async openAbout() {
    const modal = await this.modalCtrl.create({
      component: AboutPage,
      cssClass: 'wide-modal'
    });
    await modal.present();
  }

  async openDischarges() {
    const modal = await this.modalCtrl.create({
      component: DischargesPage,
      backdropDismiss: false,
      cssClass: 'wide-modal'
    });
    await modal.present();
  }
  async openSafetyguidelines() {
    const modal = await this.modalCtrl.create({
      component: SafetyguidelinesPage,
      backdropDismiss: false,
      cssClass: 'wide-modal'
    });
    await modal.present();
  }
  async openLegend() {
    const modal = await this.modalCtrl.create({
      component: LegendPage,
      backdropDismiss: false,
    });
    await modal.present();
  }

  async openCheckinLocation() {
    const modal = await this.modalCtrl.create({
      component: StatusPage,
      backdropDismiss: false,
      cssClass: 'wide-modal',
    });
    await modal.present();
  }
}
