import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ModalController } from '@ionic/angular';
import { PatientsService } from 'src/app/services/patients/patients.service';
import { AuthService } from 'src/app/services/_core/auth/auth.service';
import { NotificationsService } from 'src/app/services/_core/notifications/notifications.service';
import { User } from 'src/app/models/user';
import { Discharge } from 'src/app/models/patient';
@Component({
  selector: 'app-discharges',
  templateUrl: './discharges.page.html',
  styleUrls: ['./discharges.page.scss'],
})
export class DischargesPage implements OnInit {
  dischargeList: Discharge[] = [];
  authUser: User;
  subscriptions: Subscription[] = [];
  noDischargesMessage = '';

  constructor(
    private modalCtrl: ModalController,
    private patientService: PatientsService,
    private notificationsService: NotificationsService,
    private authService: AuthService
  ) {
    this.subscriptions.push(
      this.authService.authUser.subscribe(val => this.authUser = val)
    );
  }

  ngOnInit() {
    this.getDischargeList(this.authUser.lhu_seq);
  }
  ionViewWillEnter() {
    this.authUser = this.authService.authUser.value;
  }

  async getDischargeList(lhuSeq: number): Promise<boolean> {
    try {
      const res = await this.patientService.getDischargeList(lhuSeq).toPromise();
      if (res) {
        this.dischargeList = res.patients;
        if (this.dischargeList.length === 0) {
          this.noDischargesMessage = 'There have been no recent discharges';
        }
      }
      return Promise.resolve(true);
    } catch (err) {
      this.notificationsService.handleError(err, 'getAllPatients()');
    }
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }
}
