/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Patient, PatientControl } from 'src/app/models/patient';
import { User } from 'src/app/models/user';
import { environment } from 'src/environments/environment';
import { AuthService } from '../_core/auth/auth.service';
import { NotificationsService } from '../_core/notifications/notifications.service';

@Injectable({
  providedIn: 'root'
})
export class PatientsService {
  env = environment;
  authUser: User;
  patients: Patient[] = [];
  patientControl: PatientControl = {};
  editPatient: BehaviorSubject<Patient> = new BehaviorSubject({});
  selectedPatient: Patient[] = [];
  patientlist$: Subject<Patient[]> = new Subject();
  refreshList: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private notificationService: NotificationsService
  ) {
    //authService.getAuthUser();
  }

  public getPatientListData$(): Observable<Patient[]> {
    // console.log('*** get observable', this.patientlist$)
    return this.patientlist$.asObservable();
  }

  getPatientControl(): PatientControl {
    return this.patientControl;
  }
  setPatientControl(patControl) {
    this.patientControl = patControl;
  }

  getEditPatient(): Patient {
    return this.editPatient.getValue();
  }
  setEditPatient(patient: Patient) {
    this.editPatient.next(patient);
  }

  addToSelectedPatientList(patientList: Patient[]) {
      this.selectedPatient = patientList;
  }

  //https://mobiledev.api.baystatehealth.org/serounds/getPatients
  // eslint-disable-next-line @typescript-eslint/naming-convention
  getAllPatients(lhuSeq: number, sortOrder: string) {
    this.authUser = this.authService.getAuthUser();
    const url = `${environment.apiUrl}/getPatients`;
    const body = {
      lhu_seq: lhuSeq,
      sortOrder,
      obPatientList: this.authUser.obPatientList
    };
    return this.http.post(url, body).pipe(
      // eslint-disable-next-line arrow-body-style
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notificationService.handleError(
          err,
          'patient-service: getAllPatients(): ' + url
        );
        return of(err);
      })
    );
  }

  getByAccount(acct: string) {
    this.authUser = this.authService.getAuthUser();
    const url = `${environment.apiUrl}/getByAccount`;
    const body = {
      acct,
      username: this.authUser.loginId,
      password: this.authUser.loginPassword
    };
    return this.http.post(url, body).pipe(
      // eslint-disable-next-line arrow-body-style
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notificationService.handleError(
          err,
          'patient-service: getByAccount(): ' + url
        );
        return of(err);
      })
    );
  }

  getDischargeList(lhuSeq: number) {
    const url = `${environment.apiUrl}/getDischargeList`;
    const body = {
      lhu_seq: lhuSeq
    };
    return this.http.post(url, body).pipe(
      // eslint-disable-next-line arrow-body-style
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notificationService.handleError(
          err,
          'patient-service: getDischargeList(): ' + url
        );
        return of(err);
      })
    );
  }

  getPatientNotes(patid: string | number) {
    const url = `${environment.apiUrl}/getPatientNotes`;
    const body = {
      id: patid
    };
    return this.http.post(url, body).pipe(
      // eslint-disable-next-line arrow-body-style
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notificationService.handleError(
          err,
          'patient-service: getPatientNotes(): ' + url
        );
        return of(err);
      })
    );
  }

  getPatientPhoto(patid: string) {
    const url = `${environment.apiUrl}/getPatientPhoto`;
    const body = {
      pat_seq: patid
    };
    return this.http.post(url, body).pipe(
      // eslint-disable-next-line arrow-body-style
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notificationService.handleError(
          err,
          'patient-service: getPatientPhoto(): ' + url
        );
        return of(err);
      })
    );
  }
  savePhoto(patSeq: any, photostring: any) {
    this.authUser = this.authService.getAuthUser();
    const url = `${environment.apiUrl}/savePatientPhoto`;
    const body = {
      pat_seq: patSeq,
      photo_detail: '',
      photo_string: photostring,
      loginid: this.authUser.loginId
    };
    console.log('save photo', body);
    return this.http.post(url, body).pipe(
      // eslint-disable-next-line arrow-body-style
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notificationService.handleError(
          err,
          'patient-service: savePatientPhoto(): ' + url
        );
        return of(err);
      })
    );
  }
  savePatient(pat: Patient) {
    this.authUser = this.authService.getAuthUser();
    const url = `${environment.apiUrl}/savePatient`;
    const body = {
      loginid: this.authUser.loginId,
      lhu_seq: this.authUser.lhu_seq,
      id: pat.id,
      firstname: pat.firstname,
      lastname: pat.lastname,
      encounterid: pat.encounterid,
      acct: pat.acct,
      personid: pat.personid,
      gender: pat.gender,
      assignedroom_seq: pat.assignedroom_seq,
      interval_seq: pat.interval_seq,
      random: pat.random,
      patient_note: pat.patient_note,
      bg_color: pat.bg_color
    };
    console.log('save patient', body);
    return this.http.post(url, body).pipe(
      // eslint-disable-next-line arrow-body-style
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notificationService.handleError(
          err,
          'patient-service: savePatient(): ' + url
        );
        return of(err);
      })
    );
  }
  dischargePatient(pat: Patient) {
    this.authUser = this.authService.getAuthUser();
    const url = `${environment.apiUrl}/deletePatient`;
    const body = {
      loginid: this.authUser.loginId,
      lhu_seq: this.authUser.lhu_seq,
      id: pat.id,
      firstname: pat.firstname,
      lastname: pat.lastname,
      encounterid: pat.encounterid,
      acct: pat.acct,
      personid: pat.personid,
      gender: pat.gender,
      assignedroom_seq: pat.assignedroom_seq,
      interval_seq: pat.interval_seq,
      random: pat.random,
      patient_note: pat.patient_note,
      bg_color: pat.bg_color
    };
    console.log('discharge patient', body);
    return this.http.post(url, body).pipe(
      // eslint-disable-next-line arrow-body-style
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notificationService.handleError(
          err,
          'patient-service: deletePatient(): ' + url
        );
        return of(err);
      })
    );
  }
  //Check-in Patient
  checkinPatients(patlistselected: Patient[]) {
    this.authUser = this.authService.getAuthUser();
    const url = `${environment.apiUrl}/checkinPatients`;
    const body = {
      loginid: this.authUser.userId,
      lhu_seq: this.authUser.lhu_seq,
      checkin_lr_seq: this.patientControl.checkin_lr_seq,
      checkin_type: this.patientControl.checkin_type,
      sortorder: this.patientControl.order,
      sleepcomment: this.patientControl.sleepcomment,
      patients: patlistselected
    };
    console.log('checkinPatient patient', body);
    return this.http.post(url, body).pipe(
      // eslint-disable-next-line arrow-body-style
      map((data: any) => {
        this.patientControl.checkin_lr_seq = '0';
        this.patientControl.checkin_type = '';
        this.patientControl.checkin_desc = '';
        this.patientControl.sleepcomment = '';
        this.setPatientControl(this.patientControl);
        return data;
      }),
      catchError((err) => {
        this.notificationService.handleError(
          err,
          'patient-service: checkinPatient(): ' + url
        );
        return of(err);
      })
    );
  }
}
