/* eslint-disable @typescript-eslint/naming-convention */
import { User } from 'src/app/models/user';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AuthService } from '../_core/auth/auth.service';
import { DDO, Gender, Random, UserRole, UserAuthincation, RoomType  } from 'src/app/models/ddo';
@Injectable({
  providedIn: 'root'
})
export class DdoptionsService {
  env = environment;
  authUser: User;
  //
  //ddolist$: Subject<DDO[]>;
  //roomtypelist: BehaviorSubject<RoomType> = new BehaviorSubject({}); //RoomType
  roomtypelist: RoomType[];
  //
  genderlist: Gender[];
  randomlist: Random[];
  intervallist: DDO[];
  userrolelist: UserRole[];
  userauthlist: UserAuthincation[];
  bgcolorlist: DDO[];
  // obactivityLevellist: DDO[];
  obreportfromrnlist: DDO[];
  obsafeenvironmentlist: DDO[];
  obadlslist: DDO[];
  obnutritionlist: DDO[];
  obassistmealslist: DDO[];
  obioreportlist: DDO[];
  obrestraintslist: DDO[];
  obbedalarmlist: DDO[];
  obchairalarmlist: DDO[];
  obfallrisksignlist: DDO[];
  listLoadStatus: BehaviorSubject<boolean> = new BehaviorSubject(false);
  unitType: BehaviorSubject<string> = new BehaviorSubject('PSYC');
  constructor(
    private http: HttpClient,
    public authService: AuthService
  ) {
    authService.getAuthUser();
    this.createStaticLists();
  }

  createStaticLists() {
    //Create Gender
    this.genderlist = [];
    this.genderlist.push({ label: 'Male', id: 'M' });
    this.genderlist.push({ label: 'Female', id: 'F' });
    this.genderlist.push({ label: 'Unknown', id: 'U' });
    this.genderlist.push({ label: 'Transgender', id: 'T' });
    this.genderlist.push({ label: 'Neutral', id: 'N' });
    //UserRole
    this.userrolelist = [];
    this.userrolelist.push({ label: 'USER', id: 'USER' });
    this.userrolelist.push({ label: 'ADMIN', id: 'ADMIN' });
    //User Authinication Type
    this.userauthlist = [];
    this.userauthlist.push({ label: 'CIS', id: 'CS' });
    this.userauthlist.push({ label: 'Active Directory', id: 'AD' });
  }

  getRandomList(): Random[] {
    this.authUser = this.authService.getAuthUser();
    this.randomlist = [];
    this.randomlist.push({ label: 'Random On', value: 1 });
    if (!this.authUser.randomlock) {
      this.randomlist.push({ label: 'Random Off', value: 0 });
    }
    return this.randomlist;
  }

  getUnitType(): string {
    return this.unitType.getValue();
  }
  getRoomTypeList(): RoomType[] {
    return this.roomtypelist;
  }

  async createRoomTypeList(): Promise<boolean> {
    try {
      const returnData = await this.getDDOValue('LR_TYPE').toPromise();
      if (returnData.options.length > 0) {
        this.roomtypelist = returnData.options;
        // console.log('roomType',this.roomtypelist);
      }
    } catch (err) {
      console.log('****ERROR IN create getLocTypeList****', err);
      return Promise.reject(err);
    };
  }

  async createIntervalList(): Promise<any> {
    try {
      const returnData = await this.getDDOValue('PAT_INTERVAL').toPromise();
      this.intervallist = returnData.options;
      return Promise.resolve(true);
    } catch (err) {
      console.log('****ERROR IN create IntervalList****');
      return Promise.reject(err);
    }
  }
  async createBGColorList(): Promise<boolean> {
    try {
      const returnData = await this.getDDOValue('BGCOLOR').toPromise();
      this.bgcolorlist = returnData.options;
      return Promise.resolve(true);
    } catch (err) {
      console.log('****ERROR IN create bgcolorlist****');
      return Promise.reject(err);
    }

  }

  async setUnitType(): Promise<boolean> {
    try {
      const returnData = await this.getDDOValue('UNIT_TYPE').toPromise();
      if (returnData.options.length > 0) {
        //console.log('****Unit Type****', returnData);
        if (returnData.options[0].value_text) {
          this.unitType.next(returnData.options[0].value_text);
         } else {
          this.unitType.next('PSYC');
        }
      }
      return Promise.resolve(true);
    } catch (err) {
      console.log('****ERROR IN create obfallrisksignlist****');
      return Promise.reject(err);
    }
  }
  // Observation Fields

  async createOBAdlsList(): Promise<boolean> {
    try {
      const returnData = await this.getDDOValue('OB_ADLS').toPromise();
      if (returnData.options.length > 0) {
        this.obadlslist = returnData.options;
      }
      return Promise.resolve(true);
    } catch (err) {
      console.log('****ERROR IN create obadlslist****');
      return Promise.reject(err);
    }
  }

  async createOBNutritionList(): Promise<boolean> {
    try {
      const returnData = await this.getDDOValue('OB_NUTRITION').toPromise();
      if (returnData.options.length > 0) {
        this.obnutritionlist = returnData.options;
      }
      return Promise.resolve(true);
    } catch (err) {
      console.log('****ERROR IN create obnutritionlist****');
      return Promise.reject(err);
    }

  }

  async createOBRestraintsList(): Promise<boolean> {
    try {
      const returnData = await this.getDDOValue('OB_RESTRAINTS').toPromise();
      if (returnData.options.length > 0) {
        this.obrestraintslist = returnData.options;
      }
      return Promise.resolve(true);
    } catch (err) {
      console.log('****ERROR IN create obrestraintslist****');
      return Promise.reject(err);
    }
  }

  async createOBBedAlarmList(): Promise<boolean> {
    try {
      const returnData = await this.getDDOValue('OB_BED_ALARM').toPromise();
      if (returnData.options.length > 0) {
        this.obbedalarmlist = returnData.options;
      }
      return Promise.resolve(true);
    } catch (err) {
      console.log('****ERROR IN create createOBBedAlarmList****');
      return Promise.reject(err);
    }
  }

  async createOBReportFromRNList(): Promise<boolean> {
    try {
      const returnData = await this.getDDOValue('OB_REPORT_FROM_RN').toPromise();
      if (returnData.options.length > 0) {
        this.obreportfromrnlist = returnData.options;
      }
      return Promise.resolve(true);
    } catch (err) {
      console.log('****ERROR IN create createOBReportFromRNList****');
      return Promise.reject(err);
    }
  }
  async createOBSafeEnvironmentList(): Promise<boolean> {
    try {
      const returnData = await this.getDDOValue('OB_SAFE_ENVIRONMENT').toPromise();
      if (returnData.options.length > 0) {
        this.obsafeenvironmentlist = returnData.options;
      }
      return Promise.resolve(true);
    } catch (err) {
      console.log('****ERROR IN create createOBSafeEnvironmentList****');
      return Promise.reject(err);
    }
  }
  async createOBAssistMealsList(): Promise<boolean> {
    try {
      const returnData = await this.getDDOValue('OB_ASSIST_MEALS').toPromise();
      if (returnData.options.length > 0) {
        this.obassistmealslist = returnData.options;
      }
      return Promise.resolve(true);
    } catch (err) {
      console.log('****ERROR IN create createOBAssistMealsList****');
      return Promise.reject(err);
    }
  }
  async createOBIOReportList(): Promise<boolean> {
    try {
      const returnData = await this.getDDOValue('OB_IO_REPORT_RN').toPromise();
      if (returnData.options.length > 0) {
        this.obioreportlist = returnData.options;
      }
      return Promise.resolve(true);
    } catch (err) {
      console.log('****ERROR IN create createOBIOReportList****');
      return Promise.reject(err);
    }
  }

  async createOBChairAlarmList(): Promise<boolean> {
    try {
      const returnData = await this.getDDOValue('OB_CHAIR_ALARM').toPromise();
      if (returnData.options.length > 0) {
        this.obchairalarmlist = returnData.options;
      }
      return Promise.resolve(true);
    } catch (err) {
      console.log('****ERROR IN create obchairalarmlist****');
      return Promise.reject(err);
    }
  }
  async createOBFallRiskSignList(): Promise<boolean> {
    try {
      const returnData = await this.getDDOValue('OB_FALL_RISK_SIGN').toPromise();
      if (returnData.options.length > 0) {
        this.obfallrisksignlist = returnData.options;
      }
      return Promise.resolve(true);
    } catch (err) {
      console.log('****ERROR IN create obfallrisksignlist****');
      return Promise.reject(err);
    }
  }

  getDDOValue(field: string): Observable<any> {
    this.authUser = this.authService.getAuthUser();
    const url = `${this.env.apiUrl}/getDDOValues`;
    const body = {
      lhu_seq: this.authUser.lhu_seq,
      field
    };
    //console.log('getDDOValue body', body);
    return this.http.post(url, body).pipe(
      map((data: any) => data)
    );
  }

}
