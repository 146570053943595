import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Location, SleepNote } from 'src/app/models/location';
import { LocationsService } from 'src/app/services/locations/locations.service';
import { PatientsService } from 'src/app/services/patients/patients.service';
import { PatientControl } from 'src/app/models/patient';
@Component({
  selector: 'app-status',
  templateUrl: './status.page.html',
  styleUrls: ['./status.page.scss'],
})
export class StatusPage implements OnInit {
  showSegmentTabs = false;
  currentView: 'activity' | 'sleep' | 'loading' = 'loading';
  tempRoomList: Location[] = [];
  sleepNoteList: SleepNote[] = [];
  patientControl: PatientControl = {};
  selectedSleepNote = '';
  selectedCheckinLrSeq = '';
  constructor(
    private modalCtrl: ModalController,
    private locationsService: LocationsService,
    private patientService: PatientsService
  ) { }

  ngOnInit() {
  }

  onClickLocation(rl) {
    //console.log('Room Clicked', rl);
    this.selectedCheckinLrSeq = rl.lr_seq;
    this.selectedSleepNote = '';
    this.patientControl.checkin_type = 'M';
    if (this.selectedCheckinLrSeq) {
      for (const lr of this.tempRoomList) {
        // eslint-disable-next-line eqeqeq
        if (lr.lr_seq+'' == this.selectedCheckinLrSeq) {
          this.patientControl.checkin_code = lr.code;
          this.patientControl.checkin_desc = lr.desc;
        }
      }
    }
    this.setPatientControl();
    this.dismiss();
  }

  onClickNote(sn) {
    //console.log('NoteClicked', sn);
    this.selectedCheckinLrSeq = '80';
    this.selectedSleepNote = sn.defaulttext;
    this.patientControl.checkin_type = 'S';
    this.patientControl.checkin_desc = 'In room - ' + sn.defaulttext;
    this.setPatientControl();
    this.dismiss();
  }
  setPatientControl() {
    this.patientControl.checkin_lr_seq = this.selectedCheckinLrSeq;
    this.patientControl.sleepcomment = this.selectedSleepNote;

    console.log('***PAT CONTROL', this.patientControl);
    this.patientService.setPatientControl(this.patientControl);
  }

  async loadPage() {
    this.tempRoomList = this.locationsService.templocroomList;
    this.sleepNoteList = this.locationsService.sleepnoteList;
    //console.log('checkinlocation tempRoomList', this.tempRoomList);
  }
  async ionViewWillEnter() {
    await this.loadPage();
    this.showSegmentTabs = true;
    this.currentView = 'activity';
  }

  changeSegment(val) {
    // console.log('changeSegment: ', val);
    this.currentView = val.detail.value;
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }
}
