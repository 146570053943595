import { HelperUtilitiesService } from './../helper-utilities/helper-utilities.service';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { Platform } from '@ionic/angular';
//import { OneSignal } from '@awesome-cordova-plugins/onesignal/ngx';
/**
 * ID: bh-push-notifications-service
 * Name: BH Push Notifications Service
 * Description: Service used for handling push notifications
 * Version: 1
 *
 * ==============================
 * Change Log
 * ==============================
 * 2021-07-02 - DW - v1: Initial dev
 * 2022-June - DW - v2: Updated for ionic6
 */
@Injectable({
  providedIn: 'root'
})
export class PushService {
  env = environment;

  constructor(
    public http: HttpClientModule,
    public platform: Platform,
    //private oneSignal: OneSignal,
    private helpers: HelperUtilitiesService
  ) { }
  init() {
    // if (!this.helpers.isNotBrowser) {
    //   console.log('--In push -Non Cordova no notifications-- ');
    // } else {
    //   console.log('--In push-- ');

      // Use at least node v18.2
      //
      // To install OneSignal with Capacitor:
      // ===============================================
      // npm i onesignal-cordova-plugin@2.11.3
      // npm i @ionic-native/onesignal@5.31.1
      // npm install @awesome-cordova-plugins/onesignal
      // ionic cap sync
      // ionic capacitor build ios

      //const notificationOpenedCallback = (jsonData) => {
      //  console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
      //};

       //Replace with apps onsignal Ids
      //this.oneSignal.startInit('581c0654-250c-4f22-820d-ac9241aa0855','280912109869');

    //   this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.InAppAlert);

    //   this.oneSignal.handleNotificationReceived().subscribe(() => {
    //     console.log('handleNotificationReceived');
    //   });

    //   this.oneSignal.handleNotificationOpened().subscribe(() => {
    //     console.log('handleNotificationOpened');
    //   });
    //   this.oneSignal.getIds().then((ids) => {
    //     console.log('ONE SIGNAL getIds: ' + JSON.stringify(ids));
    //  });

    //  this.oneSignal.endInit();
    //}
  }

  getUserIds() {
  //  this.oneSignal.getIds().then((ids) => {
  //     console.log('**ONE SIGNAL getUserIds: ' + JSON.stringify(ids));
  //  });
  }
}
