/* eslint-disable @typescript-eslint/naming-convention */
import { Location, SleepNote } from 'src/app/models/location';
import { User } from 'src/app/models/user';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AuthService } from '../_core/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class LocationsService {
  env = environment;
  locroomlist: Location[] = [];
  templocroomList: Location[] = [];
  assignedroomList: Location[] = [];
  sleepnoteList: SleepNote[] = [];

  location: BehaviorSubject<Location> = new BehaviorSubject({});

  authUser: User;
  constructor(
    private http: HttpClient,
    public authService: AuthService
  ) {
    authService.getAuthUser();
  }

  getLocation(): Location {
    return this.location.getValue();
  }
  setLocation(location: Location) {
    this.location.next(location);
  }

  async createRoomLists(): Promise<boolean> {
    try {
      const returnData = await this.getLocRooms().toPromise();
      if (returnData) {
        this.locroomlist = returnData;
        // console.log('createRoomLists loc service', this.locroomlist);
        for (const lr of this.locroomlist) {
          // console.log('in loop', lr);
          if (lr.roomtype_label === 'Temp') {
            this.templocroomList.push(lr);
          } else if (lr.roomtype_label === 'OffSite') {
            this.templocroomList.push(lr);
          } else if (lr.roomtype_label === 'Room') {
            this.assignedroomList.push(lr);
          }
        }
      }
    } catch (err) {
      console.log('****ERROR IN create createRoomLists****', err);
      return Promise.reject(err);
    };
  }
  async createSleepNoteLists(): Promise<boolean> {
    try {
      const returnData = await this.getSleepNotes().toPromise();
      if (returnData) {
        this.sleepnoteList = returnData;
      }
    } catch (err) {
      console.log('****ERROR IN create createSleepNoteLists****', err);
      return Promise.reject(err);
    };
  }

  getLocRooms(): Observable<any> {
    this.authUser = this.authService.getAuthUser();
    const url = `${this.env.apiUrl}/getLocationRooms`;
    const body = {
      lhu_seq: this.authUser.lhu_seq
    };
    return this.http.post(url, body).pipe(
      map((data: any) => data.locrooms)
    );
  }

  getSleepNotes(): Observable<any> {
    this.authUser = this.authService.getAuthUser();
    const url = `${this.env.apiUrl}/getDefaultText`;
    const body = {
      lhu_seq: this.authUser.lhu_seq
    };
    return this.http.post(url, body).pipe(
      map((data: any) => data.text)
    );
  }
  //
  saveLocroom(pLocation: Location): Observable<Location> {
    this.authUser = this.authService.getAuthUser();
    pLocation.loginid = this.authUser.userId;
    pLocation.lhu_seq = this.authUser.lhu_seq;
    const body = pLocation;
    const url = `${this.env.apiUrl}/saveLocationRoom`;
    return this.http.post(url, body).pipe(
      map((data: any) => data)
    );
  }
  //
  deleteLocroom(pLocation: Location): Observable<any> {
    this.authUser = this.authService.getAuthUser();
    pLocation.loginid = this.authUser.userId;
    pLocation.lhu_seq = this.authUser.lhu_seq;
    const body = pLocation;
    const url = `${this.env.apiUrl}/deleteLocationRoom`;
    return this.http.post(url, body).pipe(
      map((data: any) => data)
    );
  }
}
